import React from 'react'
import Img from 'gatsby-image'

const breakpoint = 'lg'

export default function ProductPage({ product, site }) {
  return (
    <div className="bg-primary flex-grow-1">
      <div
        className={`container text-white d-${breakpoint}-flex pt-ms0 pt-${breakpoint}-ms4 mb-ms2`}
      >
        <div className={`w-${breakpoint}-60`}>
          <Img fluid={product.image.childImageSharp.fluid} className="w-100" />
        </div>
        <div className={`w-${breakpoint}-40 pl-${breakpoint}-ms1`}>
          <p className={`mt-ms2 mb-ms-3 mt-${breakpoint}-0`}>
            {product.name}: {product.id}
          </p>
          <p className="text-light">{product.detail}</p>
          <p className="ms1">
            {product.price !== '0' ? `ราคา${product.unit} ${product.price} บาท` : 'ราคาตามขนาด'}
          </p>
          <p>ติดต่อสอบถาม {site.phone}</p>
        </div>
      </div>
    </div>
  )
}
