import React from 'react'
import { graphql } from 'gatsby'
import TransitionLink from 'gatsby-plugin-transition-link'
import { useLocation } from '@reach/router'
import * as R from 'ramda'

import Layout, { PAGE_TRANSITION_DURATION } from 'components/layout'
import Meta from 'components/meta'
import ProductPage from 'organisms/ProductPage'
import Icon from 'components/icon'

export default function ProductTemplate({ data }) {
  const location = useLocation()
  const hackParentLink =
    location.pathname
      .split('/')
      .slice(0, -2)
      .join('/') + '/'
  const metadata = data.site.metadata
  const product = data.product.frontmatter
  const scrollPosition = R.prop('scrollPosition', location.state)
  return (
    <Layout
      actionElement={
        <TransitionLink
          className="nav-link pl-0"
          state={{
            scrollPosition,
          }}
          to={hackParentLink}
          style={{ transform: 'scale(1.1)', transformOrigin: 'left' }}
          exit={{
            length: PAGE_TRANSITION_DURATION.fade,
            state: {
              transitionMethod: 'fade',
            },
          }}
          entry={{
            length: PAGE_TRANSITION_DURATION.fade,
            delay: PAGE_TRANSITION_DURATION.fade,
            state: {
              transitionMethod: 'fade',
            },
          }}
        >
          <Icon name="chevron-left" />
          <span>ย้อนกลับ</span>
        </TransitionLink>
      }
    >
      <Meta site={metadata} title={product.name} />
      <ProductPage product={product} site={metadata} />
    </Layout>
  )
}

export const query = graphql`
  query ProductQuery($productId: String) {
    site {
      metadata: siteMetadata {
        title
        description
        siteUrl
        phone
      }
    }
    product: markdownRemark(frontmatter: { id: { eq: $productId } }) {
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 1110) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        id
        name
        detail
        price
        unit
      }
    }
  }
`
